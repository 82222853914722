/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import * as React from 'react';

import { GetStaticPaths, GetStaticProps } from 'next';
import Head from 'next/head';
import { ArticleJsonLd } from 'next-seo';
import Link from 'next/link';
import { buildURL } from 'react-imgix';
import { useIntersection, usePrevious } from 'react-use';

import BreadCrumbs from '@/components/breadcrumbs/Breadcrumbs';
import { Container, Hr, Heading, Select } from '@/components/design-system';
import EpisodeTeaserInline from '@/components/episodes/EpisodeTeaserInline';
import Main from '@/components/shared/Main';
import { ShowHeader } from '@/components/shows/ShowHeader';
import { useAuth0 } from '@/context/auth';
import { appApi, Show } from '@/graphql/app';
import { getABTestAnalyticData } from '@/lib/abTesting';
import { APP_STORE_ID, SITE_URL } from '@/lib/constants';
import { filterMemberExclusiveEpisodes } from '@/lib/memberExclusive';
import { getUtmCookieData } from '@/lib/utm_cookie';
import { useEpisodes } from '@/request/episode';
import { stripHtml } from '@/utils/helpers';

interface ShowPageProps {
  show: Show;
}

const ShowPage: React.FC<ShowPageProps> = ({ show }) => {
  const { isAuthenticated, subscriber } = useAuth0();

  React.useEffect(() => {
    const abTestData = getABTestAnalyticData();
    const utmCookieData = getUtmCookieData();

    window.__DW_Next_Bridge?.Analytics.logEvent('Segment Page', {
      ...abTestData,
      ...utmCookieData,
      title: show.name,
    });
  }, [show.name]);

  const description = show.description ? stripHtml(show.description) : '';
  const metaImageUrl = show.image ? buildURL(show.image, { w: 800, h: 600 }) : '';

  const datePublished = show.episodes && show.episodes.length ? show.episodes[0].createdAt : '';
  const absUrl = `${SITE_URL}/show/${show.slug}`;

  const isPragerUShow = show.belongsTo === 'prageru';
  const [seasonId, setSeasonId] = React.useState(show.seasons?.[0]?.id);

  const { episodes: unsortedEpisodes, size, setSize, isRefreshing, isReachingEnd } = useEpisodes(seasonId);

  const episodes = filterMemberExclusiveEpisodes(unsortedEpisodes, isAuthenticated, subscriber, 'episode');

  const latestEpisode = episodes && episodes[0]?.episode ? episodes[0].episode : null;
  const ref = React.useRef(null);
  const intersection = useIntersection(ref, { root: null, rootMargin: '0px', threshold: 1 });
  const moreInView = intersection && intersection.intersectionRatio < 1 ? false : true;
  const prevMoreInView = usePrevious(moreInView);

  React.useEffect(() => {
    if (prevMoreInView === false && moreInView === true && !isReachingEnd && !isRefreshing) {
      setSize(size + 1);
    }
  }, [isReachingEnd, isRefreshing, moreInView, prevMoreInView, setSize, size]);

  return (
    <Main>
      <Head>
        <meta content='Show' name='parsely-section' />
        <meta content='' name='parsely-tags' />
        <meta content={absUrl} name='parsely-link' />
        {metaImageUrl && <meta content={metaImageUrl} name='parsely-image-url' />}
        <title>{show?.name ?? ''}</title>
        <link href={absUrl} rel='canonical' />
        <meta content='website' property='og:type' />
        <meta content={description} name='description' />
        <meta content={show?.name ?? ''} property='og:title' />
        <meta content={description} property='og:description' />
        {metaImageUrl && <meta content={metaImageUrl} property='og:image' />}
        <meta content={absUrl} property='og:url' />
        <meta content={show?.name ?? ''} property='twitter:title' />
        <meta content={description} property='twitter:description' />
        <meta content='summary_large_image' name='twitter:card' />
        {metaImageUrl && <meta content={metaImageUrl} name='twitter:image' />}
        <meta content={`app-id=${APP_STORE_ID}, app-argument=${absUrl}`} name='apple-itunes-app' />
        <meta content={absUrl} property='al:ios:url' />
        <meta content={absUrl} property='al:android:url' />
        <meta content={absUrl} name='twitter:app:url:iphone' />
        <meta content={absUrl} name='twitter:app:url:ipad' />
        <meta content={absUrl} name='twitter:app:url:googleplay' />
      </Head>
      <ArticleJsonLd
        authorName={show.name}
        datePublished={datePublished}
        description={description}
        images={[metaImageUrl]}
        publisherLogo={`${SITE_URL}/images/logo.png`}
        publisherName='The Daily Wire'
        title={show.name}
        url={absUrl}
      />
      <ShowHeader isPragerUShow={isPragerUShow} latestEpisode={latestEpisode} show={show} />
      <Container variant='lg'>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '24px',
          }}
        >
          <Heading css={{ color: '#fff' }} variant='600'>
            Episodes
          </Heading>
          {seasonId && show.seasons && (
            <Select
              css={(theme) => ({
                overflow: 'hidden',
                width: '140px',
                [theme.mq.phablet]: {
                  width: '220px',
                },
              })}
              name='Season Select'
              onChange={(event) => setSeasonId(event.currentTarget.value)}
            >
              {show.seasons.map((season) => (
                <option key={`${show.id}-${season.id}`} value={season.id}>
                  {`${season.name}`}
                </option>
              ))}
            </Select>
          )}
        </div>
        <Hr
          css={(theme) => ({
            display: 'none',
            marginBottom: '32px',
            marginTop: '0px',

            [theme.mq.phablet]: {
              display: 'block',
            },
          })}
        />
        <div css={{ paddingBottom: '3rem' }}>
          {episodes?.map((episode) => {
            if (!episode) return null;
            return (
              // @ts-ignore
              <EpisodeTeaserInline episode={episode.episode} hideShowName={true} key={episode?.episode.id} />
            );
          })}
          <div ref={ref}>{isRefreshing ? '' : ' '}</div>
        </div>
      </Container>

      <BreadCrumbs links={[<Link href='/'>DailyWire+</Link>, <Link href='/watch'>Watch</Link>, <>{show.name}</>]} />
    </Main>
  );
};

export default ShowPage;

export const getStaticProps: GetStaticProps = async (context) => {
  const slug = context.params?.slug as string;
  const showData = await appApi.getShowBySlug({ slug });

  if (!showData.show) {
    return {
      notFound: true,
      revalidate: 600,
    };
  }

  return {
    props: { show: showData.show },
    revalidate: 600, // 10 mins (in seconds)
  };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const { shows } = await appApi.getShowSlugs();
  const paths = shows.map((show) => {
    const slug = show?.slug as string;
    return { params: { slug } };
  });
  return { paths, fallback: 'blocking' };
};
