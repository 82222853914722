/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';

import { mainBackgroundAttachment, mainBackgroundSize, mainBackgroundString } from '@/components/shared/Main';

import { Container } from '../design-system';

interface IBreadCrumbsProps {
  links: React.ReactNode[];
  showBackground?: boolean;
}

const BreadCrumbs: React.FC<IBreadCrumbsProps> = ({ links, showBackground }) => {
  return (
    <div
      className='lc-breadcrumb'
      css={{
        padding: '10px 0',
        borderTop: '0.5px solid #666',
        backgroundColor: 'rgba(39,39,39,1)',
        width: '100%',
        ...(showBackground
          ? {
              background: mainBackgroundString,
              backgroundSize: mainBackgroundSize,
              backgroundAttachment: mainBackgroundAttachment,
            }
          : {}),
      }}
    >
      <Container>
        <div css={{ margin: '0 auto', color: 'white', fontSize: 11 }}>
          {links.map((LinkNode, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={i}>
              {LinkNode}

              {i < links.length - 1 && <span className='lc-breadcrumb-separator'>&nbsp; &nbsp;&gt;&nbsp;&nbsp;</span>}
            </React.Fragment>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default BreadCrumbs;
