import * as React from 'react';

import { imgixLoader } from '@delicious-simplicity/next-image-imgix-loader';
import cn from 'classnames';
import Image from 'next/image';
import { useMedia } from 'react-use';

import { Container } from '@/components/ui';

import s from './MediaHero.module.css';

export interface IMediaHero {
  title: string;
  logo?: string;
  backgroundImage?: string;
  alignItems?: 'center' | 'bottom';
  children?: React.ReactNode;
  className?: string;
  spacing?: 'default' | 'none';
}

export const MediaHero = React.memo<IMediaHero>(
  ({ children, backgroundImage, logo, title, alignItems = 'bottom', spacing = 'default', className = '' }) => {
    const isDesktop = useMedia('(min-width: 1000px)');

    const width = React.useMemo(() => {
      if (typeof window === 'undefined') return;
      if (isDesktop) return 2520;
      return 1000;
    }, [isDesktop]);

    const height = React.useMemo(() => {
      if (typeof window === 'undefined') return;
      if (isDesktop) return 945;
      return 525;
    }, [isDesktop]);

    const ar = React.useMemo(() => {
      if (typeof window === 'undefined') return;
      if (isDesktop) return '4:1.5';
      return '2:1.05';
    }, [isDesktop]);

    const rect = React.useMemo(() => {
      if (typeof window === 'undefined') return;
      if (isDesktop) return 'center';
      return '960,bottom,2400,1260';
    }, [isDesktop]);

    const layout = React.useMemo(() => {
      if (typeof window === 'undefined') return;
      if (isDesktop) return 'fill';
      return 'responsive';
    }, [isDesktop]);

    const showImage = backgroundImage && width && height && layout && ar && rect;

    return (
      <div
        className={cn(
          s.root,
          { [s.alignCenter]: alignItems === 'center', [s.spacingNone]: spacing === 'none' },
          className,
        )}
      >
        <div className={s.bgImgWrap}>
          {showImage && (
            <Image
              alt={title ?? ''}
              height={height}
              layout={layout}
              loader={(props) =>
                imgixLoader(props, {
                  ar: ar,
                  crop: 'bottom',
                  fit: 'crop',
                  rect: rect,
                })
              }
              objectFit='cover'
              src={backgroundImage}
              width={width}
            />
          )}
        </div>
        <Container className={s.container} spacing='none' variant='xxl'>
          <div className={s.content}>
            {logo && (
              <div className={s.logoWrap}>
                <Image
                  alt={title ?? ''}
                  height={128}
                  loader={(props) =>
                    imgixLoader(props, {
                      fit: 'clip',
                      h: '128',
                    })
                  }
                  objectFit='contain'
                  src={logo}
                  width={380}
                />
              </div>
            )}

            {children}
          </div>
        </Container>
      </div>
    );
  },
);

MediaHero.displayName = 'MediaHero';
